<template>
  <v-text-field
    dense
    :disabled="disabled"
    :label="label"
    :value="value"
    @input="$emit('input', $event)"
    :type="type"
    v-bind="attrs"
    v-on="on"
    :suffix="suffix ? `(${suffix})` : ''"
    :prefix="prefix ? `${prefix}` : ''"
    :tabindex="tabindex"
    :readonly="readonly"
    hide-details
  />
</template>
<script>
export default {
  props: [
    "value",
    "label",
    "items",
    "disabled",
    "attrs",
    "on",
    "suffix",
    "prefix",
    "tabindex",
    "type",
    "readonly",
  ],
};
</script>
