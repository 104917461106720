<template>
  <div class="orderFormGrid">
    <div class="gap-2">
      <v-menu
        ref="menu_date_register"
        v-model="menu_date_register"
        :close-on-content-click="false"
        :return-value.sync="order.date_register"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <DenseTextField
            :disabled="form_disable"
            v-model="order.date_register"
            label="발주일"
            :on="on"
            :attrs="attrs"
            :readonly="true"
          ></DenseTextField>
        </template>
        <DatePicker
          v-model="order.date_register"
          :max="$store.state.pickerMax"
          :min="$store.state.pickerMin"
          @input="$refs.menu_date_register.save(order.date_register)"
        >
        </DatePicker>
      </v-menu>
    </div>
    <div class="gap-2">
      <v-menu
        ref="menu_date_duedate"
        v-model="menu_date_duedate"
        :close-on-content-click="false"
        :return-value.sync="order.date_duedate"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <DenseTextField
            :disabled="form_disable"
            v-model="order.date_duedate"
            label="납기일자"
            :on="on"
            :attrs="attrs"
            :readonly="true"
          ></DenseTextField>
        </template>
        <DatePicker
          v-model="order.date_duedate"
          :max="$store.state.pickerMax"
          :min="$store.state.pickerMin"
          @input="$refs.menu_date_duedate.save(order.date_duedate)"
        >
        </DatePicker>
      </v-menu>
    </div>
    <div class="gap-2"></div>
    <div>
      <DenseLabelSelect
        :disabled="form_disable || !order._id == false || order.kind === 3"
        label="년도"
        v-model="order.order_front_year"
        :items="$store.state.yearList"
        @change="orderFrontChage()"
      />
    </div>
    <div>
      <DenseLabelSelect
        :disabled="form_disable || !order._id == false || order.kind === 3"
        label="시즌"
        v-model="order.order_front_season"
        :items="seasons"
        @change="orderFrontChage()"
      />
    </div>

    <template v-if="$store.state.company.input_order_status">
      <div class="gap-1">
        <DenseTextField
          :disabled="form_disable || !order._id == false || order.kind === 3"
          label="ORDER NO"
          v-model="order.order"
        ></DenseTextField>
      </div>
      <div class="gap-1">
        <DenseTextField
          label="BUYER ORDER NO"
          v-model="order.buyer_order"
        ></DenseTextField>
      </div>
      <div class="gap-2 pt-2">
        <div class="pr-2 d-flex align-center">
          <input
            class="v-checkbox"
            :disabled="form_disable"
            type="checkbox"
            :checked="order.SampleStatus"
            @click="SampleStatusChange($event)"
          />

          <span class="checkboxLabelText"> Sample </span>
        </div>
        <!-- <v-checkbox
          :disabled="!order._id == false || order.kind === 3"
          dense
          label="Sample"
          v-model="order.SampleStatus"
          @change="SampleStatusChange($event)"
          hide-details
        ></v-checkbox> -->
      </div>
    </template>
    <template v-else>
      <div class="gap-2">
        <DenseTextField
          disabled
          label="ORDER NO"
          v-model="orderNo"
        ></DenseTextField>
      </div>
      <div class="gap-2 pt-2">
        <div class="pr-2 d-flex align-center">
          <input
            class="v-checkbox"
            :disabled="form_disable"
            type="checkbox"
            :checked="order.SampleStatus"
            @click="SampleStatusChange($event)"
          />

          <span class="checkboxLabelText"> Sample </span>
        </div>
        <!-- <v-checkbox
          :disabled="!order._id == false || order.kind === 3"
          dense
          label="Sample"
          v-model="order.SampleStatus"
          @change="SampleStatusChange($event)"
        ></v-checkbox> -->
      </div>
    </template>
    <div class="gap-2">
      <DenseDialogClientComboBox
        :disabled="form_disable"
        label="구매처"
        v-model="order.client"
        :items="clientList"
        ref="clientComboBox"
        @keyup.prevent="
          addNewSettingForm('Client', $event, $refs['clientComboBox'])
        "
        @click="openEditDialog($event, 'Client')"
        :dense="true"
        item-text="name"
      >
      </DenseDialogClientComboBox>
    </div>
    <div>
      <DenseTextField
        disabled
        label="담당자"
        :value="order.client ? order.client.liaison : ''"
        type="text"
      ></DenseTextField>
    </div>
    <div>
      <DenseTextField
        disabled
        dense
        label="전화번호"
        :value="order.client ? order.client.tel : ''"
        type="text"
      />
    </div>
    <div class="gap-2">
      <DenseTextField
        disabled
        dense
        label="주소"
        :value="order.client ? order.client.address : ''"
        type="text"
      />
    </div>
    <div>
      <DenseComboBox
        :disabled="form_disable"
        label="복종"
        v-model="order.ctype"
        :items="ctypeList"
        ref="ctypeComboBox"
        item-text="name"
        @keyup.prevent="
          addNewSettingForm('Ctype', $event, $refs['ctypeComboBox'])
        "
        @click="openEditDialog($event, 'Ctype')"
      ></DenseComboBox>
    </div>
    <div>
      <DenseLabelSelect
        :disabled="form_disable"
        label="성별"
        v-model="order.gender"
        :items="genders"
      />
    </div>
    <div class="gap-4" />

    <div class="gap-2">
      <DenseSubItemComboBox
        :disabled="form_disable"
        label="아이템 (자사)"
        v-model="order.item"
        :items="itemList"
        ref="itemComboBox"
        item-text="name"
        @keyup.prevent="
          addNewSettingForm('Item', $event, $refs['itemComboBox'])
        "
        @click="openEditDialog($event, 'Item')"
        :dense="true"
        @change="changeItem($event)"
      ></DenseSubItemComboBox>
    </div>
    <div class="gap-2">
      <DenseTextField
        disabled
        label="아이템 (생산처)"
        v-model="order.item.production_item"
        type="text"
      ></DenseTextField>
    </div>
    <div class="gap-2">
      <DenseTextField
        :disabled="form_disable"
        label="가격"
        v-model="order.item_price"
        type="number"
        :suffix="order.item.unit_price_sign"
      ></DenseTextField>
    </div>
    <div class="gap-2">
      <DenseTextField
        :disabled="form_disable"
        v-model="order.item_slope"
        label="경사"
      ></DenseTextField>
    </div>
    <div class="gap-2">
      <DenseTextField
        :disabled="form_disable"
        v-model="order.item_oblique"
        label="위사"
      ></DenseTextField>
    </div>
    <div class="gap-2">
      <DenseTextField
        :disabled="form_disable"
        v-model="order.item_variety"
        label="혼용률"
      ></DenseTextField>
    </div>
    <div>
      <DenseTextField
        :disabled="form_disable"
        v-model="order.item_width"
        label="폭"
        :suffix="order.item.unit_width"
      ></DenseTextField>
    </div>
    <div>
      <DenseTextField
        :disabled="form_disable"
        v-model="order.item_density"
        label="밀도"
      ></DenseTextField>
    </div>
    <div>
      <DenseTextField
        :disabled="form_disable"
        v-model="order.item_weight"
        label="중량"
        :suffix="order.item.unit_weight"
      ></DenseTextField>
    </div>
    <div class="gap-3">
      <DenseTextField
        :disabled="form_disable"
        v-model="order.item_post_processing"
        label="후가공"
      ></DenseTextField>
    </div>
    <div>
      <DenseLabelSelect
        :disabled="form_disable"
        label="오더 유형"
        v-model="order.order_kind"
        :items="$store.state.order_kind"
      />
    </div>
    <div>
      <DenseLabelSelect
        :disabled="form_disable"
        label="매입 구분"
        v-model="order.purchase_classification"
        :items="purchase_classification"
      />
    </div>
    <div>
      <DenseLabelSelect
        :disabled="form_disable"
        label="매출 구분"
        v-model="order.sales_classification"
        :items="sales_classification"
      />
    </div>
    <div>
      <DenseLabelSelect
        :disabled="form_disable"
        label="통화 단위"
        v-model="order.eschamge_sign"
        :items="$store.state.currency_sign"
      />
    </div>
    <div>
      <DenseTextField
        v-if="order.liaison"
        disabled
        label="담당자"
        v-model="order.liaison"
        return-obejct
        type="object"
      ></DenseTextField>
      <DenseTextField
        v-else
        disabled
        label="담당자"
        v-model="this.$store.state.auth_name"
        return-obejct
        type="object"
      ></DenseTextField>
    </div>
    <div />
    <div class="gap-2">
      <DenseTextField
        :disabled="form_disable"
        label="매출단가"
        return-obejct
        v-model="order.sales_unit_price"
        @input="(e) => (order.sales_unit_price = usingComma(e))"
        :prefix="order.eschamge_sign"
      ></DenseTextField>
    </div>
    <div class="gap-2 unitPriceComma text-overflow">
      <DenseTextField
        :disabled="form_disable"
        label="매출 Surcharge"
        return-obejct
        v-model="order.sales_surcharge"
        @input="(e) => (order.sales_surcharge = usingComma(e))"
        :prefix="order.eschamge_sign"
      ></DenseTextField>
    </div>
    <!--  
    <div class="gap-2 unitPriceComma text-overflow">
      <DenseTextField
        :disabled="form_disable"
        label="선수금"
        return-obejct
        v-model="order.advance_pay"
        @input="(e) => advancePayInput(e)"
        :prefix="order.eschamge_sign"
      ></DenseTextField>
    </div> 
    <div class="gap-2 unitPriceComma text-overflow">
      <v-menu
        ref="menu_date_advance"
        v-model="menu_date_advance"
        :close-on-content-click="false"
        :return-value.sync="order.advance_date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <DenseTextField
            :disabled="form_disable || advancePayDisable()"
            v-model="order.advance_date"
            label="수금일"
            :on="on"
            :attrs="attrs"
            :readonly="true"
          ></DenseTextField>
        </template>
        <DatePicker
          v-model="order.advance_date"
          :max="$store.state.pickerMax"
          :min="$store.state.pickerMin"
          @input="$refs.menu_date_advance.save(order.advance_date)"
        >
        </DatePicker>
      </v-menu>
    </div>
    <div class="gap-2 unitPriceComma text-overflow">
      <DenseDialogClientComboBox
        :disabled="form_disable || advancePayDisable()"
        label="선수금업체"
        v-model="order.advance_client"
        :items="clientList"
        ref="clientComboBox"
        @keyup.prevent="
          addNewSettingForm('Advance', $event, $refs['clientComboBox'])
        "
        @click="openEditDialog($event, 'Client')"
        :dense="true"
        item-text="name"
      >
      </DenseDialogClientComboBox>
    </div> 
    <div class="gap-2 unitPriceComma text-overflow pt-2">
      <div class="pr-2 d-flex align-center">
        <input
          class="v-checkbox"
          :disabled="form_disable || advancePayDisable()"
          type="checkbox"
          :checked="order.advance_isNotvat"
          @click="order.advance_isNotvat = !order.advance_isNotvat"
        />

        <span class="checkboxLabelText">
          <template v-if="!order.advance_isNotvat"> 선수금VAT포함 </template>
          <template v-if="order.advance_isNotvat"> 선수금VAT미포함 </template>
        </span>
      </div>
    </div> -->

    <div class="gap-6">
      <v-textarea
        v-model="order.remark"
        rows="9"
        label="비고"
        hide-details
        class="lefted-input orderTextArea"
        clearable
        :disabled="form_disable"
        no-resize
        background-color="grey lighten-5"
      />
    </div>
  </div>
</template>

<script>
import DenseTextField from "@/components/atoms/VTextField/DenseTextField.vue";
import DenseLabelSelect from "@/components/atoms/VSelect/DenseLabelSelect.vue";
import DatePicker from "@/components/atoms/Vdate/DatePicker.vue";
import DenseDialogClientComboBox from "@/components/atoms/VComboBox/DenseDialogClientComboBox.vue";
// import DenseDialogComboBox from "@/components/atoms/VComboBox/DenseDialogComboBox.vue";
import DenseComboBox from "@/components/atoms/VComboBox/DenseComboBox.vue";
import DenseSubItemComboBox from "@/components/atoms/VComboBox/DenseSubItemComboBox.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    DenseTextField,
    DenseLabelSelect,
    DatePicker,
    DenseDialogClientComboBox,
    // DenseDialogComboBox,
    DenseComboBox,
    DenseSubItemComboBox,
  },
  data() {
    return {
      // form - 승인
      dialog_form_request: false,

      dialog_form_companion: false,
      Delete_form_request: false,
      // form - dialog - 견적가
      dialog_estimate: false,
      // form - dialog  단가
      dialog_unitPrice: false,
      // form - dialog - New Buyer
      dialog_client: false,
      dialog_ctype: false,
      dialog_item: false,
      dialog_color: false,
      menu_date_register: false,
      menu_date_duedate: false,
      menu_date_advance: false,
      // orderNo: "",
      seasons: [
        {
          value: "",
          label: "선택 안함",
        },
        {
          value: "ss",
          label: "S/S",
        },
        {
          value: "fw",
          label: "F/W",
        },
      ],
      genders: [
        {
          value: "",
          label: "선택 안함",
        },
        {
          value: "m",
          label: "남성",
        },
        {
          value: "w",
          label: "여성",
        },
      ],
      purchase_classification: [
        {
          value: "domestic",
          label: "내수",
        },
        {
          value: "overseas",
          label: "수입",
        },
      ],
      sales_classification: [
        {
          value: "domestic",
          label: "내수",
        },
        {
          value: "export",
          label: "수출",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      order: "getOrderForm",
      itemList: "getUseItemtList",
      userList: "getUserList",
      ctypeList: "getUseCtypeList",
      clientList: "getUseClientList",
    }),

    // 단가 수정버튼 Disable Status
    orderMasterDisable() {
      return this.$store.state.auth_grade <= 2 && this.order.liaison
        ? this.order.liaison.name === this.$store.state.auth_name
          ? false
          : true
        : false;
    },
    // 승인요청 수정요청,완료된 오더 Disable
    form_disable() {
      return ["10", "00", "50"].includes(this.order.status);
    },
    orderNo() {
      return this.order._id
        ? this.order.order
        : this.$store.state.newOrderNo.order;
    },
  },
  methods: {
    //EditDialogOpen
    openEditDialog(item, type) {
      this.$store.commit("setEditedItemForm");
      this.editForm = { ...item };
      this.editForm.type = type;
      this.editForm.business_num
        ? (this.editForm.business_num = this.getBusinessNumMask(
            this.editForm.business_num,
          ))
        : "";

      this.editForm.tel
        ? (this.editForm.tel = this.getPhoneMask(this.editForm.tel))
        : "";
      this.editForm.production = this.editForm.production
        ? this.$store.state.clientList.find(
            (x) => "" + x._id === "" + this.editForm.production._id,
          )
        : "";

      this.editForm.unit_price = this.editForm.unit_price
        ? this.usingComma(this.editForm.unit_price)
        : "";

      this.$store.commit("setEditedItemForm", this.editForm);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },

    // Buyer,아이템 No 등 목록에 없는 항목인지 체크하는 함수
    addNewSettingForm(type, e, componet) {
      if (e.key !== "Enter") return;
      const editedItem = {};
      let dupleCheckValue;

      if (e.target.value !== null) {
        this.$store.commit("setEditedItemForm");
        componet.$children[0].isMenuActive = false;
        //해당 값이 목록에 대소문자 구분없이 체크
        if (type === "Client") {
          dupleCheckValue = this.clientList.find(
            (x) =>
              x.name.trim().toLowerCase() ===
              (typeof e.target.value === "object"
                ? e.target.value.name.trim().toLowerCase()
                : e.target.value.trim().toLowerCase()),
          );
        }
        if (type === "Buyer") {
          dupleCheckValue = this.clientList.find(
            (x) =>
              x.name.trim().toLowerCase() ===
              (typeof e.target.value === "object"
                ? e.target.value.name.trim().toLowerCase()
                : e.target.value.trim().toLowerCase()),
          );
        } else if (type === "Ctype") {
          dupleCheckValue = this.ctypeList.find(
            (x) =>
              x.name.toLowerCase() ===
              (typeof e.target.value === "object"
                ? e.target.value.name.toLowerCase()
                : e.target.value.trim().toLowerCase()),
          );
        } else if (type === "Item") {
          dupleCheckValue = this.itemList.find(
            (x) =>
              x.name.toLowerCase() ===
              (typeof e.target.value === "object"
                ? e.target.value.name.toLowerCase()
                : e.target.value.trim().toLowerCase()),
          );
        }
      }

      //해당 값이 목록에 없는지 체크 후 다이얼로그 Open
      if (
        e.target.value !== null &&
        e.target.value !== "" &&
        (dupleCheckValue === undefined || dupleCheckValue === null)
      ) {
        editedItem.kind = 1;
        editedItem.type = type;
        editedItem.currency_sign = "₩";
        editedItem.unit_length = "y";
        editedItem.unit_width = "in";
        editedItem.unit_weight = "g/y";
        editedItem.inspect_status = true;

        type === "Client" || type === "Buyer"
          ? (editedItem.name_only =
              typeof e.target.value === "object"
                ? e.target.value.name
                : e.target.value)
          : (editedItem.name =
              typeof e.target.value === "object"
                ? e.target.value.name
                : e.target.value);
        this.$store.commit("setEditedItemForm", editedItem);
        this.$store.commit("setDailogStatus", {
          status: true,
          kind: "setting_form",
        });
      }
      if (dupleCheckValue) {
        type === "Client"
          ? (this.order.client = dupleCheckValue)
          : type === "Buyer"
          ? (this.order.buyer = dupleCheckValue)
          : type === "Advance"
          ? (this.order.advance_client = dupleCheckValue)
          : type === "Ctype"
          ? (this.order.ctype = dupleCheckValue)
          : type === "Item"
          ? (this.order.item = dupleCheckValue)
          : "";
      }
    },
    /*
    1. 년도 또는 시즌 입력 받을 경우 order_front_season 자동으로 생성
    2. order No 생성해주는 action 함수 호출
    **/
    orderFrontChage() {
      this.order.order_front = `${this.order.order_front_year.substr(2, 4)}${
        this.order.order_front_season
      }${this.order.kind === 2 ? "-sam" : ""}`;
      if (!this.order.kind) this.order.kind = 1;

      this.$store.dispatch("NEW_ORDER_NO");
    },
    SampleStatusChange(status) {
      if (status) this.order.kind = 2;
      else this.order.kind = 1;
      this.orderFrontChage();
    },
    changeItem(item) {
      this.order.item_price = item.unit_price;
      this.order.item_slope = item.slope;
      this.order.item_oblique = item.oblique;
      this.order.item_variety = item.variety;
      this.order.item_width = item.width;
      this.order.item_density = item.density;
      this.order.item_weight = item.weight;
      this.order.item_post_processing = item.post_processing;
    },
    advancePayInput(e) {
      this.order.advance_pay = this.usingComma(e);
    },
    advancePayDisable() {
      return !this.order.advance_pay ? true : false;
    },
  },
};
</script>
